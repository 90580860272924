.ineerdiv {
    padding: 20px;
    border: 1px solid #E0E0E0;
    margin-bottom: 20px;
}

.category-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

.category-card-img-top {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 150px;
    /* Adjust as needed */
    margin: 0 auto;
    background-color: #f8f9fa;
}

.farme-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

/* .category-card-img-top {
    width: 50%;
    height: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.catogarysection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.cardhedings {
    font-size: 16px;
    text-align: center;
}

.farme {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
}

.frame-images {
    height: auto;
    width: 100%;
    max-width: 300px;
    margin: 8px;
}

@media (max-width: 767px) {
    .groupimg {
        height: auto;
        width: 100%;
    }

    .frame-images {
        max-width: 100%;
    }

    .ineerdiv {
        padding: 10px;
    }

    .cardhedings {
        font-size: 14px;
    }

    .catogarysection {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 20px;
    }

    .card-body {
        padding: 0.5rem;
    }

    .category-card-img-top {
        height: 100px;
    }

    .farme {
        flex-direction: column;
    }
}