.slider-container {
    margin: 0 auto;
    padding: 40px 0;
}

.slider-card {
    text-align: center;
    padding: 10px;
    /* Adjust padding as needed */
}

.image-wrapper {
    width: 100%;
    height: 200px;
    /* Fixed height for all images */
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-wrapper img {
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 10px;
}

.category-name {
    color: #333;
}

@media (max-width: 767px) {
    .slick-prev {
        left: -10px;
    }

    .slick-next {
        right: -10px;
    }
}