.footerlogo {
    display: flex;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    border-radius: 15px;
    height: 150px;
    width: 210px;
}
.logoimg{
    height: auto;
    width: 80%;
}
.footersection p{
    font-size: 14px;
    text-align: justify;
}
.hedingdiv{
    display: flex;
    flex-direction: row;
    align-items: center;
}