.menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    width: 200px;
    padding: 10px 0;
}

.menu-dropdown ul {
    margin: 0;
    padding: 0;
}

.menu-dropdown li {
    margin: 0;
    padding: 0;
}

.menu-dropdown .nav-link {
    color: black;
    text-decoration: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.menu-dropdown .nav-link:hover {
    background: #f8f9fa;
}

.navbar-text {
    display: flex;
    align-items: center;
    margin-right: 10px;
    /* Adjust as needed */
}

.navbar-text .fa-icon {
    margin-right: 5px;
    /* Adjust as needed */
}

.iconcolor {
    color: #5B549E;
}