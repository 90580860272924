.terms-container {
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.terms-container h1 {
    margin-top: 0;
}

.terms-container a {
    color: #007bff;
    text-decoration: none;
}

.terms-container a:hover {
    text-decoration: underline;
}