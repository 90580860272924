.bg-filler {
    background-color: #f8f9fa;
}

.shadow {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    background-color: #f8f9fa;
}

.text-bold {
    font-weight: bold;
}

.text-purpule {
    color: #5B549E;
    font-size: 65px;
}

.search-input {
    border-radius: 1px solid black !important;
}

.herodiv {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    padding: 20px;
    /* Add padding to ensure it looks good on all devices */
}

.hero-img {
    width: 100%;
    height: auto;
    max-width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin-top: 20px;
    /* Adjust margin as needed */
}

.rowtext {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    /* Adjust margin as needed */
}

.input-container {
    display: flex;
    align-items: center;
    width: 100%;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding-left: 0.5rem;
    height: 38px;
}

.inputheight {
    width: 100%;
    height: 100%;
}

.location-input {
    max-width: 400px;
}

@media (max-width: 767.98px) {
    .herodiv {
        padding: 20px;
        /* Add some padding for smaller screens */
    }

    .hero-img {
        width: 100%;
        height: auto;
        margin-top: 0;
        /* Adjust margin for mobile view */
    }

    .rowtext {
        flex-direction: column;
    }

    .input-container {
        margin-bottom: 10px;
    }

    .location-input {
        margin-top: 10px;
        width: 100%;
        /* Full width on smaller screens */
    }

    .finalhomehero {
        margin-top: 20px;
    }
}

/* @media (max-width: 767px) {
    .text-center img {
        width: 100%;
        height: auto;
    }
} */