/* Updated styles */
.custom-btn {
  background: #d3d3d3;
  font-size: 10px;
  padding: 5px 10px;
  border-radius: 50px;
}

.service-card-main {
  height: 80%;
  border-radius: 10px;
  border: 0.2px salmon #565656 !important;
}

.card-img-top {
  width: 100%;
  height: 40%;
  overflow: hidden;

}

.cardimgtop {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-body span {
  font-size: 12px;
  font-weight: bold;
  margin-left: 10px;
  color: #5b549e;

}

.custom-btn:nth-child(2) {
  background: #90ee90;
}

.agnetname {
  height: 10%;
}

.card-heading-para {
  height: 40%;
  text-wrap: wrap;
}

.custom-btn:nth-child(3) {
  background: #ffffed;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: start;
}

@media (max-width: 1200px) {
  .row-cols-md-3 .col-md-3 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}

@media (max-width: 992px) {
  .row-cols-md-3 .col-md-3 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .row-cols-md-3 .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .card {
    margin-left: 20px;
  }

  .custom-btn {
    font-size: 12px;
    padding: 3px 7px;
    margin-top: 5px;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  .card-body {
    align-items: start;
  }

  .custom-pagination {
    margin-top: 30px;
  }
}

.card.h-100.pointer {
  transition: box-shadow 0.3s ease;
  border-radius: 10px;
}

.card.h-100.pointer:hover {
  box-shadow: 0px 10px 16px 8px rgba(0, 0, 0, 0.2);
}

/* .service-card-main:hover {
  box-shadow: 0px 10px 16px 8px rgba(0, 0, 0, 0.2);
} */

.sicon {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  color: #5b549e;
}

.servicesearch {
  padding-left: 30px;
}


.cards-title {
  min-height: 30px;
  /* Adjust based on the title size */
}

.cards-text {
  min-height: 30px;
  /* Adjust based on the expected description size */
}

.mdb-card-body {
  display: flex;
  flex-direction: column;
}

.mdb-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}