/* src/components/Service.css */

.serviceHeader {
    width: 90%;
    background: #5B549E;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    height: auto;
    min-height: 230vh;
}

.serviceHeader img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    margin-top: 20px;
}

.serviceHeader button {
    margin-top: 20px;
}

.serviceSeconddiv {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.underline {
    height: 1px !important;
    width: 100% !important;
}

.tags-input-container {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
}

.tag-item {
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 5px 10px;
    margin: 5px;
}

.remove-tag-button {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
}

input[type="text"] {
    flex-grow: 1;
    padding: 5px;
    outline: none;
}

.error {
    color: red;
    margin-top: 20px;
}

.servicebtn {
    background-color: #5B549E !important;
    margin: 10px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .serviceHeader {
        height: auto;
        width: 100%;
    }

    .serviceSeconddiv {
        align-items: center;
    }

    .tags-input-container {
        width: 100%;
    }

    .tag-item {
        margin: 3px;
    }
}