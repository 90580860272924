.payment-conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    padding: 20px;
    flex-direction: column;
    text-align: center;
    height: 100vh;
    overflow: hidden;
}

.checkicon {
    font-size: 60px;
    color: green;
}
.borderclass{
    padding: 20px;
    border: 1px solid black;
    height: auto;
    width: 50%;
    margin-top: 20px;
}
.info{
    display: flex;
    justify-content: space-between;
}
.div-button{
    display: flex;
    justify-content: space-between;
    width: 50%;
}