.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 20px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.logo img {
    height: 60px;
    margin-left: 300px;
}

.hamburger-menu {
    position: relative;
}

.hamburger-icon {
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
}

.menu-list {
    display: none;
    position: absolute;
    top: 54px;
    right: 0px;
    background-color: white;
    color: black;
    width: 200px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.menu-list.open {
    display: block;
}

.menu-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.menu-list li {
    padding: 12px 16px;
}

.menu-list li a {
    color: black;
    text-decoration: none;
}

.menu-list li a:hover {
    color: #5b549e;
}

.iconcolor {
    color: #5b549e;
}
@media (max-width :767px) {
    .logo img{
        display: none;
    }
}