.perticular-container {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.perticular-container img {
    height: 100px;
    width: 100px;
}

.conatiner-table {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cards-row {
    display: flex;
    justify-content: space-evenly;
}

.perticular-cards {
    height: auto;
    min-height: 230px;
    width: 100%;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #5B549E;

}

.color-text {
    color: #5B549E;
}

.ag-theme-alpine .ag-row {
    cursor: pointer;
}