.home-container-agnet {
    background-color: black;
    width: 100%;
    height: 100vh;
}

.text-white h1, .text-white p {
    color: white;
}

.heroheding {
    font-size: 89px;
    font-weight: 400;
}

.herop {
    font-size: 24px;
    font-weight: 200;
}

.customebtn {
    background-color: yellow;
    color: black;
    width: 212px !important;
}

.customebtn:hover {
    background-color: yellow;
    color: black;
}

.text-yellow {
    color: yellow;
}