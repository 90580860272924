.perticualrservice {
    /* FFDA91   FFFFFF */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    background-color: white;
}
.reviewdes{
    font-size: 14px;
}
.imagesdiv {
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    /* Center the gallery if needed */
}


.react-image-gallery {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
}

.react-image-gallery-slide img {
    width: 100%;
    /* Ensure images fit within the container */
    height: auto;
    /* Maintain aspect ratio */
}

.firstcol {
    height: 100%;
    background-color: #f8f8ff;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.underline {
    height: 1px;
    color: black;
}

.image-gallery-slide-wrapper .image-gallery-swipe {
    height: 350px;
    /* Adjust height as needed */
    width: 100%;
    /* Adjust width as needed */
}

.image-gallery-slide img {
    max-height: 100%;
    max-width: 100%;
}

.image-gallery-thumbnail {
    height: 100px;
    /* Adjust thumbnail height as needed */
}

/* Remove forward and backward navigation icons */
.image-gallery-icon {
    display: none;
}

/* Optionally, you can style the gallery container */
.image-gallery {
    width: 600px;
    margin: 0 auto;
}

.secondcol {
    display: flex;
    justify-content: start;
    padding: 20px;
    flex-direction: column;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.custom-button {
    color: black;
    border: 1px solid #5B549E;
    flex: 1 1 calc(50% - 10px);
    font-size: 14px;
}

.custom-button:hover {
    border-color: #5B549E !important;
    color: white;
    background-color: #5B549E;
}


@media (max-width: 768px) {
    .imagesdiv {
        max-height: 300px;
        /* Adjust according to mobile design */
    }


    .image-gallery-slides {
        text-align: start;
        width: 60%;
    }

    .image-gallery-thumbnails .image-gallery-thumbnails-container {
        text-align: start !important;
    }

}

@media (max-width: 576px) {
    .imagesdiv {
        max-height: 250px;
        /* Further adjust for smaller mobile screens */
    }

    .react-image-gallery-slide img {
        width: 100%;
        height: auto;
        /* Maintain aspect ratio */
    }
}