.mainsidebar {
  width: 100%;
  max-width: 506px;
  background: #5b549e;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-direction: column;
  overflow: hidden;
  min-height: 130vh;
}

.secondremain {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
  padding: 20px;
}

.userImg {
  height: 250px;
  width: 250px;
  border-radius: 1000px;
}

.text-start {
  width: 100%;
}

.instagram-input {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0 !important;
  box-shadow: none;
  padding-left: 30px;
}

.instagram-input:focus {
  border-bottom: 1px solid #5b549e;
  box-shadow: none;
}

.instagram-icon {
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translateY(-50%);
  pointer-events: none;
}

@media (max-width: 768px) {
  .mainsidebar {
    height: 300px;
    /* padding: 20px; */
 
  }

  .secondremain {
    height: auto;
  }

  .userImg {
    height: 100px;
    width: 100px;
  }
}