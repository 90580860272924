.navbar {
    padding: 10px 20px;
    /* Adjust padding for navbar */
}

.navbar-brand .navbar-logo {
    height: auto;
    width: 180px;
    /* Fixed width for logo */
}

.nav-link-custom,
.nav-dropdown-custom {
    color: black;
    font-size: 16px !important;
    margin: 0 15px;
    font-weight: 400 !important;
    text-decoration: none !important;
}

.nav-link-custom.active {
    color: #007bff !important;
    /* Change color of active link (like 'Login') */
    font-weight: bold;
    /* Optional: Increase font weight for active link */
}

.buttons {
    height: 50px;
    width: 130px;
    border-radius: 25px !important;
    background: #5b549e !important;
}

.dropdown-menu {
    position: absolute;
    top: 70px;
    /* Adjust based on the height of your menu icon */
    right: 0;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
    padding: 10px;
    width: 150px;
    /* Adjust as needed */
}

.dropdown-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.dropdown-item:hover {
    background: #f0f0f0;
    /* Add a hover effect */
}

.header-menu-dropdown {
    position: absolute;
    top: 20;
    right: 0;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    width: 200px;
    padding: 10px 0;
    position: fixed;
}

.header-menu-dropdown ul {
    margin: 0;
    padding: 0;
}

.header-menu-dropdown li {
    margin: 0;
    padding: 0;
}

.header-menu-dropdown .nav-link {
    color: black;
    text-decoration: none;
    padding: 10px 20px;
    display: flex;
    align-items: center;
}

.header-menu-dropdown .nav-link:hover {
    background: #f8f9fa;
}

/* Media queries */
@media (max-width: 992px) {
    .navbar-brand .navbar-logo {
        width: 150px;
        /* Adjust logo width */
    }

    .nav-link-custom,
    .nav-dropdown-custom {
        font-size: 16px !important;
        margin: 0 10px;
    }

    .buttons {
        width: 110px;
        /* Further reduce button width */
    }
}

@media (max-width: 768px) {
    .navbar-brand .navbar-logo {
        width: 120px;
        /* Further reduce logo width */
    }

    .nav-link-custom,
    .nav-dropdown-custom {
        font-size: 14px !important;
        margin: 0 5px;
    }

    .buttons {
        width: 100px;
        /* Further reduce button width */
    }
}