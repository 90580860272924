.forgetpassword {
    min-height: 100vh;
    padding: 0;
    overflow: hidden;
}

.align-items-stretch {
    display: flex;
    height: 100vh;
}

.forget-imagediv {
    background: #5B549E;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgotpasswordimg img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.forgotpassword-form {
    width: 100%;
    max-width: 400px;
}