.privacy-policy-container {
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.privacy-policy-content {
    padding: 20px;
}