.inputheight {
    height: 48px;
    /* width: 100%; */
    /* Ensure full width */
    padding-right: 40px;
    /* Add padding to the right for the icon */
}

.phone-input {
    flex: 1;
    /* Ensure it takes up the remaining space */
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.country-code-dropdown {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    /* Ensure it doesn't interfere with input focus */
}

.password-toggle {
    pointer-events: auto;
    /* Ensure the password toggle icon can be clicked */
}

.bordered-input {
    border-radius: 0;
    /* Optional: for consistent border radius */
}

.cutomebutton {
    width: 100%;
    height: 48px;
    color: white;
    background: #5b549e !important;
}

.login-image-col, .register-image-col {
    background-color: #5b549e;
    height: auto;
    padding: 0;
    min-height: 110vh;
}

.login-image {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.login-form-container {
    width: 100%;
    max-width: 418px;
    margin: 0 auto;
    /* Center the form */
}

.login-icon {
    height: 130px;
    width: 130px;
}

.create-account {
    color: #5b549e;
}

.phoneloginicon {
    margin-right: 5px;
    position: relative;
    top: -23px;
}

.loginions {
    margin-right: 11px;
    position: relative;
    top: -30px;
}

/* Media query for iPhone devices */
@media (max-width: 767.98px) {
    .inputheight {
        width: 100% !important;
    }

    .login-image-col, .register-image-col {
        height: auto;
        padding: 1rem;
    }

    .login-image {
        max-height: 300px;
    }

    .login-form-container {
        padding: 1rem;
    }

    .loginheding {
        font-size: 36px;
    }

    .cutomebutton {
        width: 100%;
        height: 48px;
    }
}