.sidebar {
    width: 250px;
    height: 100vh;
    background-color: white;
    color: black !important;
    display: flex;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-header {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    flex-grow: 1;
    margin-top: 0px;
}

.sidebar-item {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.sidebar-item:hover {
    background-color: #f0f0f0;
}

.sidebar-icon {
    margin-right: 10px;
    color: #5B549E;
}

.active {
    font-weight: bold;
}

.sidebarimg {
    height: 150px;
    width: 150px;
    border-radius: 10000px;
    object-fit: cover;
}

.sidebarbtn {
    height: 30px;
    width: 154px;
    background: #5B549E !important;
    margin-bottom: 5px;
    border-radius: 50px !important;
    border: 1px solid white !important;
    color: white;
}

.search-container {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
    position: relative;
}

.search-icon {
    margin-left: 5px;
    color: #5B549E;
    position: absolute;
    left: 10px;
}

.search-input {
    border: none;
    outline: none;
    padding: 5px 5px 5px 30px;
    flex-grow: 1;
    border-radius: 5px;
    width: 100%;
    color: black;
    border-bottom: 2px solid #ccc;
}

.toggle-button {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1100;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #5B549E;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 250px;
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .sidebar-item {
        padding: 8px 0;
    }

    .search-input {
        width: 100%;
    }

    .sidebar-menu {
        margin-top: 45px;
    }
}

@media (max-width: 480px) {
    .sidebar {
        width: 200px;
    }

    .sidebarbtn {
        width: 100%;
    }

    .sidebar-item {
        padding: 6px 0;
    }

    .search-input {
        width: 100%;
    }
}