.custome-cards-dashbord {
  height: auto;
  width: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  color: #5B549E;
  margin-bottom: 10px;
}

.ineer-dashbord-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableHading {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 767px) {
  .btn-group-wrapper {
    flex-direction: column;
    align-items: stretch;
  }

  .btn-group-wrapper .btn-group,
  .btn-group-wrapper .btn {
    width: 100%;
    margin-bottom: 10px;
  }
}