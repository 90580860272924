@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.poppins-regular {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.butoons {
  width: 127.91px;
  height: 40px;
  border-radius: 50px !important;
  background: #5B549E !important;
  color: #FFFFFF !important;
}

.footercolor {
  background: #1B1931 !important;
}

.paragraph {
  font-size: 22.29px;
  font-weight: 300;
}

.pointer {
  cursor: pointer;
  z-index: 2;
}

.longbtn {
  width: 215px;
  height: 48px;
  background: #5B549E !important;
  color: #FFFFFF !important;
  border-radius: 5px !important;
}

.bordered-input {
  border: 1px solid #ccc !important;
  /* Ensure there's a border */
}

.input-height-48 {
  height: 48px;
}

.topmargin {
  margin-top: 60px;
}

/* Pagination.css */

.custom-pagination .MuiPaginationItem-root {
  color: black;
  background-color: white;
}

.custom-pagination .MuiPaginationItem-root:hover {
  background-color: #4A4583;
}

.custom-pagination .MuiPaginationItem-page.Mui-selected {
  color: white;
  background-color: #5B549E;
}

.custom-pagination .MuiPaginationItem-page.Mui-selected:hover {
  background-color: #4A4583;
}

.custom-pagination .MuiPaginationItem-page {
  color: black;
}

.custom-pagination .MuiPaginationItem-page:hover {
  background-color: #4A4583;
  color: white;
}

.custom-pagination .MuiPaginationItem-ellipsis {
  color: white;
}

.serviceButoon {
  background: white !important;
  color: black !important;
}

.custom-link {
  text-decoration: none;
  color: inherit;
}

.custom-link:hover {
  color: inherit;
  /* Optional: maintain the color on hover */
}

@media (max-width: 767.98px) {
  .input-height-48 {
    width: 100% !important;
  }

  .longbtn {
    width: 100%;
    height: auto;
  }
}